






import Vue from 'vue';

export default Vue.component('QSkeleton', {
  props: {
    isLoaded: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: String,
      required: false,
      default: '1rem'
    },
    width: {
      type: String,
      required: false,
      default: '100%'
    },
    maxWidth: {
      type: String,
      required: false,
      default: '300px',
    }
  },
  computed: {
    skeletonClass (): string {
      if (this.isLoaded) {
        return ''
      }

      return 'skeleton'
    },
    skeletonStyle () {
      if (this.isLoaded) {
        return {};
      }

      return {
        'min-height': this.height,
        width: this.width,
        "max-width": this.maxWidth,
      }
    }
  }
})
